<template>
  <div>
    <nav class="pa-3">
      <router-link to="/assignment" class="deco">
        <v-btn dark fab small color="indigo">
          <v-icon dark> mdi-arrow-left </v-icon>
        </v-btn>
      </router-link>
    </nav>
    <div class="pa-3" v-if="!$apollo.queries.modulKasusById.loading">
      <v-card v-if="modulKasusById">
        <v-toolbar color="indigo" dense dark
          ><h3>{{ modulKasusById.kasus }}</h3></v-toolbar
        >
        <div class="pa-5">
          <div v-if="modulKasusById.chart === 'line'">
            <div v-if="chart == 'x'" class="_temp">
              <p>Belum ada data yang di tampilkan</p>
            </div>
            <line-chart :data="chart" v-else />
          </div>
          <div v-else-if="modulKasusById.chart === 'bar'">
            <div v-if="chart == 'x'" class="_temp">
              <p>Belum ada data yang di tampilkan</p>
            </div>
            <column-chart :data="chart" v-else></column-chart>
          </div>
          <div v-else>
            {{ chart }}
          </div>
        </div>
        <section v-if="modulKasusById.narasi" class="pa-3">
          <article>
            <p v-html="modulKasusById.narasi"></p>
          </article>
        </section>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { CASE_BY_ID } from "../../graphql/Module";

export default {
  name: "caseDetail",
  apollo: {
    modulKasusById: {
      query: CASE_BY_ID,
      variables() {
        return { id: this.id };
      },
      result(data) {
        let daysTo = this.daysTo;
        console.log("udah async " + daysTo);
        this.$store.dispatch("previewChart", {
          id: data.data.modulKasusById.id,
          chart: data.data.modulKasusById.chart,
          q: daysTo
        });
      },
      error(error) {
        console.error(error);
      }
    }
  },

  data() {
    return {
      dataChart: null,
      curent: null,
      loading: false,
      modulKasusById: null
    };
  },
  computed: {
    ...mapState(["chart", "countDay", "visible"]),
    id() {
      return parseInt(this.$route.params.id);
    },
    daysTo() {
      return parseInt(localStorage.getItem("day"));
    }
  }
};
</script>

<style>
._temp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border: black 1px dotted;
}
.deco {
  text-decoration: none;
}
</style>
